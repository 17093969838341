@font-face {
    font-family: 'LobsterTwo-Bold';
    src: url('./../fonts/LobsterTwo-Bold.ttf');
}

@font-face {
    font-family: 'LobsterTwo-Regular';
    src: url('./../fonts/LobsterTwo-Regular.ttf');
}
@font-face {
    font-family: 'DMSans-Bold';
    src: url('./../fonts/DMSans-Bold.ttf');
}

@font-face {
    font-family: 'DMSans-Regular';
    src: url('./../fonts/DMSans-Regular.ttf');
}



@font-face {
    font-family: 'DMSans-Medium';
    src: url('./../fonts/DMSans-Medium.ttf');
}


.color-main{
    color: #10919e;

}

.color-secondary{
    color: #9f86d9;

}
.font-1-bold{
    font-family: LobsterTwo-Bold;
}

.font-1{
    font-family: LobsterTwo-Regular;
}

.font-2-bold{
    font-family: DMSans-Bold;
}

.font-2{
    font-family: DMSans-Regula;
}

.logo-text {
    margin: 0px;
    font-family: LobsterTwo-Bold;
    text-transform: capitalize;
    color: #10919e;
    font-weight: 900;
}

.category-name{
    color: #9f86d9;
    font-size: 34px;
    font-family: LobsterTwo-Bold;
}
.cursor-pointer{
    cursor: pointer;
}
.nav-tabs{
    list-style: none;
    border: none;
    display: flex;
    align-items: center;
    margin: 0px;
}
.tabs{
    font-size: 28px;
    color: #9f86d9;
    font-family: LobsterTwo-Regular;
    cursor: pointer;
}

@keyframes opacity-up {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes opacity-down {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.pagenation-items{
    color: #9f86d9;
    font-family: LobsterTwo-Bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}
.pagenation-link{
    color: #9f86d9;
    font-family: LobsterTwo-Bold;
    user-select: none;
    height: 40px;
    text-decoration: none;
    width: 40px;    
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination .active a{
    background-color: #9f86d9;
    color: white;
    border-radius: 50%;
}
.text-gray{
    color:#878787;
}